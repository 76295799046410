import React from "react"
import { graphql } from "gatsby"

import BaseLayout from "../components/layout/base-layout"
import TileGrid from "../components/link-grid/tile-grid"
import { ContentWrapper, Hero } from "@wonder-horse/shared"
import DonateSection from "../components/donate/donate-section"
import FlyerGrid from "../components/flyer-grid/flyer-grid"

const Home = ({ data }) => {
  const {
    pagesJson: { heading, intro, tiles },
    file: {
      childImageSharp: { gatsbyImageData },
    },
    markdownRemark: { html },
  } = data

  return (
    <BaseLayout title="Home">
      <Hero img={gatsbyImageData} />
      <ContentWrapper>
        <h1 className="text-center font-medium">{heading}</h1>
        <p className="w-full md:w-3/5 text-center">{intro}</p>
        <TileGrid tiles={tiles} />
        <DonateSection html={html} />
        <FlyerGrid />
      </ContentWrapper>
    </BaseLayout>
  )
}

export const query = graphql`
  query HomeQuery {
    pagesJson(name: { eq: "home" }) {
      heading
      intro
      name
      tiles {
        color
        img
        link {
          label
          path
        }
      }
    }
    file(relativePath: { eq: "home/home-hero-bw.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    markdownRemark(frontmatter: { name: { eq: "how-to-help" } }) {
      html
    }
  }
`
export default Home
