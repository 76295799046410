import React from "react"
import Donate from "./donate"
import ContentSection from "../layout/content-section"

const DonateSection = ({ html }) => (
  <ContentSection>
    <div dangerouslySetInnerHTML={{ __html: html }} />
    <Donate />
  </ContentSection>
)

export default DonateSection
