import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const NavigationTile = ({
  tile: {
    link: { path, label },
    color,
  },
  img,
}) => (
  <Link
    to={path}
    className={`relative w-full md:w-72 h-40 md:h-72 shadow-md hover: shadow-2xl transition duration-500 ease-in-out flex justify-center items-center ${color} rounded-lg p-20 text-center no-underline`}
  >
    <div className="absolute w-full h-full opacity-10 overflow-hidden rounded-lg hover:opacity-90 transition duration-500 ease-in-out">
      <GatsbyImage alt="navigation-tile" image={img} />
    </div>
    <div className="text-2xl text-gy-0 font-bold hover:text-bp-900">
      {label}
    </div>
  </Link>
)

export default NavigationTile
