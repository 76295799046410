import React from "react"
import NavigationTile from "./navigation-tile"
import useTileImgQuery from "../../hooks/use-tile-img-query"

const TileGrid = ({ tiles }) => {
  const nodes = useTileImgQuery()
  const navTiles = tiles.map(tile => {
    const {
      node: {
        childImageSharp: { gatsbyImageData },
      },
    } = nodes.find(({ node }) => node.name === tile.img)
    return (
      <NavigationTile
        tile={tile}
        img={gatsbyImageData}
        key={`nav-card-${tile.link.label}`}
      />
    )
  })
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 gap-8 mt-6 mb-10">
      {navTiles}
    </div>
  )
}

export default TileGrid
