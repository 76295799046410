import React from "react"

const ContentSection = ({ title, children, center = false }) => {
  const textAlign = center ? "text-center" : ""
  return (
    <section className={`m-4 w-11/12 md:m-16 md:w-7/12 ${textAlign}`}>
      {title && <h3>{title}</h3>}
      {children}
    </section>
  )
}

export default ContentSection
