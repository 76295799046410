import { graphql, useStaticQuery } from "gatsby"
const useFlyerQuery = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query FlyerQuery {
      allFile(
        filter: { relativeDirectory: { eq: "program-flyers/2023/june" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)
  return edges
}

export default useFlyerQuery
