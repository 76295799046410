export const categoryTheme = {
  social: {
    background: "bg-r-100",
    border: "border-r-900",
    button: "bg-r-700",
    hover: "bg-r-900",
  },
  training: {
    background: "bg-b-100",
    border: "border-b-900",
    button: "bg-b-700",
    hover: "bg-b-900",
  },
  care: {
    background: "bg-gr-100",
    border: "border-gr-900",
    button: "bg-gr-700",
    hover: "bg-gr-900",
  },
}
