import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import useFlyerQuery from "../../hooks/use-flyer-query"
import ContentSection from "../layout/content-section"
import RegisterButton from "../program-list/register-button"

const FlyerGrid = () => {
  const nodes = useFlyerQuery()
  const flyerList = nodes.map(({ node }) => {
    const {
      childImageSharp: { gatsbyImageData },
      name,
    } = node

    const margin = nodes.length > 1 ? "md:mr-5" : ""
    return (
      <div className={`w-full md:w-1/2 mb-5 md:mb-0 ${margin}`} key={name}>
        <a
          href={gatsbyImageData.images.fallback.src}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage alt={name} image={gatsbyImageData} />
        </a>
      </div>
    )
  })

  const justify = nodes.length === 1 ? "md:justify-center" : ""
  return (
    <ContentSection title="Upcoming Workshops" center={flyerList.length === 1}>
      <div className="py-5">
        <RegisterButton />
      </div>
      <div className={`flex flex-col md:flex-row ${justify}`}>{flyerList}</div>
    </ContentSection>
  )
}

export default FlyerGrid
