import React from "react"
import DonateButton from "./donate-button"

const Donate = () => (
  <div className="w-full md:w-3/4 mt-6 md:mt-8">
    <DonateButton />
    <div className="text-xs italic mt-2">
      Donations are securely collected through the PayPal website
    </div>
  </div>
)
export default Donate
