import React from "react"
import { categoryTheme } from "./programs.theme"

const REGISTER_DEFAULT =
  "https://share.hsforms.com/18mIj89IYQpa0s0rcqr0zRQdstqi"
const THEME_DEFAULT = categoryTheme["training"]

const RegisterButton = ({
  link = REGISTER_DEFAULT,
  theme = THEME_DEFAULT,
  paused,
}) =>
  !!paused ? (
    <div
      className={`w-full md:w-min px-8 py-2 bg-gy-500 text-gy-0 rounded transition no-underline cursor-default text-center`}
    >
      <span className="text-sm uppercase tracking-wider">Register</span>
    </div>
  ) : (
    <a
      className={`inline-block w-full md:inline text-center md:w-min px-8 py-2 ${theme.button} text-gy-0 rounded transition hover:${theme.hover} hover:shadow-lg no-underline`}
      href={link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <span className="text-sm uppercase tracking-wider">Register</span>
    </a>
  )

export default RegisterButton
